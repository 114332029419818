







































































import ModalDeleteAccountingFirm from "@/components/modal/accountingFirm/ModalDeleteAccountingFirm.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LoadingStatusType, BannerStatus, BannerOpenType, AccountingFirmStatus } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";

import AuthService from "@/service/authService";
const StoreAccountingFrim = namespace("AccountingFrim");
const auth = new AuthService();
@Component({
  components: {
    ModalDeleteAccountingFirm,
  },
})
export default class AccountingFirmDetail extends Vue {
  @StoreAccountingFrim.Action
  private DoGetAccountingFirmDetail!: (id: string) => void;
  @StoreAccountingFrim.Getter
  private getAccountingFirmsDetailLoadingStatus!: LoadingStatusType;
  @StoreAccountingFrim.Getter
  private getAccountingFirmsDetail!: any | null;
  private accountingFirmDetail: any = null;
  private id = "";
  private isShowDeleteAccountingFirmModal = false;
  private editPermission = false;
  private deletePermission = false;
  accountingFirmStatus = AccountingFirmStatus;

  constructor() {
    super();
    this.getAccountingFirmPermission();
  }
  created() {
    this.GetAccountingFirmDetail();
  }
  @Watch("getAccountingFirmsDetailLoadingStatus", { immediate: true })
  getAccountingFirmsDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      if (this.getAccountingFirmsDetail) {
        this.accountingFirmDetail = this.getAccountingFirmsDetail;
      }
    }
  }
  public GetAccountingFirmDetail() {
    const emtStr: any = this.$route.query.id;
    this.id = emtStr;
    this.DoGetAccountingFirmDetail(this.id);
  }
  public RedirectToAccountingFirmTable() {
    window.location.href = "/accountingFirmTable";
  }
  public Delete() {
    this.isShowDeleteAccountingFirmModal = true;
  }
  public EditAccountingFirm() {
    const routeData = this.$router.resolve({
      name: "accountingFirmEdit",
      query: { id: this.accountingFirmDetail.id },
    });
    window.open(routeData.href, "_blank");
  }
  public async getAccountingFirmPermission() {
    const accessPermission = await auth.getPermissions();
    this.editPermission = accessPermission["accountingFirm"]["edit"];
    this.deletePermission = accessPermission["accountingFirm"]["delete"];
  }
}
